<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="editPaperValidation">
            <b-form @submit.prevent>
                <b-card>
                    <template v-if='paper'>
                        <b-row>
                            <b-col><h3>{{ $t("general.edit_paper") }}</h3></b-col>
                            <b-col class="text-right">
                                <b-button variant='warning' @click="$router.push({name: 'faq'})">{{ $t('general.help')}}</b-button>
                                <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                            </b-col>
                        </b-row>
                        <b-row class='mt-1'>
                            <b-col cols='12' sm='6' >
                                <validation-provider
                                    :name="$t('general.title')"
                                    #default="{ errors }"
                                    rules="required|max:250"
                                >
                                    <label for='title'>{{ $t('general.title') }}</label>
                                    <b-form-input id='title' v-model="paper.Title"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                            <b-col cols='12' sm='6' class='mt-1 mt-sm-0'>
                                <label for="type">{{ $t('general.type') }}</label>
                                <v-select id="type" :clearable='false' :options='types' label="Name" v-model="selected_type"></v-select>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1">
                            <b-col cols='12' md='6'>
                                <label for="selected_area">{{ $t('general.area') }}</label>
                                <v-select @input='areasLimiter' label='Name' multiple :options='areas' id='selected_area' v-model="selectedArea"></v-select>
                            </b-col>
                            <b-col cols='12' md='6' class='mt-1 mt-md-0'>
                                <label for="interdisciplinary">{{ $t('general.interdisciplinary') }}</label>
                                <b-form-checkbox id="interdisciplinary" @input='onInterdisciplinaryChange' v-model="isInterdisciplinary"></b-form-checkbox>
                            </b-col>
                        </b-row>


                        <b-row class="mt-1">
                            <b-col cols='12' md='6'>
                                <label for="authors">{{ $t('general.authors') }}</label>
                                <v-select  @input='authorsLimiter' :getOptionLabel='getPersonLabel' multiple :options='authors' id='authors' v-model="selected_authors"></v-select>
                            </b-col>
                            <b-col cols='12' md='6' class='mt-1 mt-md-0'>
                                <label for="menthors">{{ $t('general.menthors') }}</label>
                                <v-select @input="menthorsLimiter" :getOptionLabel='getFullnameFromMentor' multiple :options='mentors' id='menthors' v-model="selected_mentors"></v-select>
                            </b-col>
                        </b-row>
                        <b-row class='mt-1'>
                            <b-col>
                                <validation-provider
                                    :name="$t('general.summary')"
                                    #default="{ errors }"
                                    rules=""
                                >
                                    <label for='summary'>{{ $t('general.summary') }}</label>
                                    <b-form-textarea v-model="paper.Summary" id='summary'></b-form-textarea>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col class="text-right">
                                <b-button @click="validationForm" variant="primary">{{ $t('general.save') }}</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BForm, BOverlay, BCard, BFormInput, BFormCheckbox, BFormTextarea, BButton} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required, max } from '@validations'

    export default {
        components: {BCol, BRow, BForm, BOverlay, BCard, BFormInput, BFormCheckbox, BFormTextarea, BButton, vSelect, ValidationProvider, ValidationObserver},
        data() {
            return {
                showLoader: false,
                years: [],
                selected_year: null,
                schoolList: [],
                selected_school: null,
                types: [],
                selected_type: null,

                fields: [
                    {
                        key: 'type',
                        label: this.$t('general.type')
                    },
                    {
                        key: 'area',
                        label: this.$t('general.area')
                    },
                    {
                        key: 'paper',
                        label: this.$t('general.paper')
                    }
                ],
                papersResults: [],
                authors: [],
                selected_authors: [],
                areas: [],
                selectedArea: [],
                mentors: [],
                selected_mentors: [],

                isInterdisciplinary: false,

                schoolResponse: null,
                userRole: 'sola',

                paper: null,
                isChangingPermitted: false,

                required,
                max
            }
        },
        methods: {
            getPersonLabel(person) {
                return `${person.Firstname} ${person.Lastname}`
            },
            getFullnameFromMentor(person) {
                return `${person.ProfessionalTitle} ${person.Firstname} ${person.Lastname}`
            },
            onInterdisciplinaryChange(isChecked) {
                if (!isChecked) {
                    this.selectedArea.splice(1, this.selectedArea.length - 1)
                }
            },
            areasLimiter(e) {
                // ce ni interdisciplinarno, lahko izbere samo eno področje
                if (!this.isInterdisciplinary && e.length > 1) {
                    e.pop()
                }

                let limit = 2
                if (this.$store.state.user.userData.SchoolType.Abbreviation === 'SŠ') limit = 3

                // OŠ lahko izbere max 2 področja, SŠ pa max 3
                if (e.length > limit) {
                    e.pop()
                }
            },
            menthorsLimiter(e) {
                if (e.length > 3) e.pop()
            },
            authorsLimiter(e) {
                if (e.length > 3) e.pop()
            },

            validationForm() {
                this.$refs.editPaperValidation.validate().then((response) => {
                    if (response) {
                        this.onEditPaper()
                    }
                })
            },
            async loadData() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get(`/v1/sola/naloga/${thisIns.id}`)
                    .then(res => {
                        thisIns.paper = res.data
                        thisIns.selected_authors = res.data.Authors
                        thisIns.selected_mentors = res.data.Mentors

                        if (thisIns.paper.InterdisciplinaryID === 1) {
                            thisIns.isInterdisciplinary = true
                            thisIns.selectedArea = []
                            thisIns.selectedArea = thisIns.paper.Fields
                        } else {
                            thisIns.selectedArea = []
                            thisIns.selectedArea = thisIns.paper.Fields
                        }
                        
                        thisIns.getSchoolTypes()
                        thisIns.getAuthors()
                        thisIns.getMentors()
                        thisIns.getFields()
                        thisIns.checkIfChangeIsPermitted()
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            async checkIfChangeIsPermitted() {
                this.isChangingPermitted = await this.$checkIfChangeIsPermitted()

                if (!this.isChangingPermitted) this.$router.replace('/')

            },
      
            onEditPaper() {
                const thisIns = this
                if (this.isInterdisciplinary && thisIns.selectedArea.length <= 1) {
                    this.$printWarning(this.$t('general.interdisciplinary_two_areas_selected'))
                    return
                }
                thisIns.showLoader = true

                const podrocjaID = thisIns.selectedArea
                const avtorjiID = thisIns.selected_authors
                const mentorID = thisIns.selected_mentors

                // ce je interdisciplinarno podrocje, posljem ID podrocja kot single ID
                // drugace pa posljem array IDjev
                const payload = {
                    InterdisciplinaryID: (thisIns.isInterdisciplinary ? 1 : 2),
                    TypeID: thisIns.selected_type.ID,
                    Title: thisIns.paper.Title,
                    Summary: thisIns.paper.Summary,
                    Authors: avtorjiID,
                    Mentors: mentorID,
                    Fields: podrocjaID
                }

                thisIns.$http.patch(`/v1/sola/naloga/${  thisIns.id}`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.paper_successfully_edited')}`)
                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.push({name: 'school-research-papers'})
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.edit_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })

            },
            getSchoolTypes() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/vrstaNaloge')
                    .then((response) => {
                        thisIns.types = response.data
                        for (const i of response.data) {
                            if (thisIns.paper.TypeID === i.ID) {
                                thisIns.selected_type = i
                            }
                        }
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getAuthors() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/avtorji')
                    .then((response) => {
                        thisIns.authors = response.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getMentors() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/mentorji')
                    .then((response) => {
                        thisIns.mentors = response.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getFields() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/podrocja')
                    .then((response) => {
                        thisIns.areas = response.data
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        created()  {
            this.id = this.$route.params.id
            this.userRole = this.$store.state.user.userData.user_role
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
